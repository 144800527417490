import React from 'react'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type DataPropsSocialMedia = {
  icon: string;
  socialMedia: string;
  description: string;
  link: string | undefined;
  section: string;
  sectionName: string;
  descriptionLink: string;
}

const SocialMediaCard = (props: DataPropsSocialMedia) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <div className='border rounded-4 social-media-card row'>
      <div className='col-12'>
        <p className='text-orange--extra fs-16 lh-20 fw-600 font-inter'>
          <OrangeIcon color='#FF7A00' icon={props.icon} size='MD' />
          <span dangerouslySetInnerHTML={{ __html: props.socialMedia }} />
        </p>
      </div>
      <div className='col-12 description'>
        <p className='text-grayscale--500 fs-16 lh-20 fw-600 limited-p mb-0' dangerouslySetInnerHTML={{ __html: props.description }} />
        <a
          href={props.link} target='_blank' rel='noreferrer'
          className='fs-14 text-orange--extra'
          onClick={() => {
          sendDatalayerEvent({
            section: props.section,
            section_name: props.sectionName,
            element_action: 'click button',
            element_name: props.socialMedia + ', ' + props.descriptionLink,
            redirect_url: props.link,
          })
          }}
        >
          <span dangerouslySetInnerHTML={{ __html: props.descriptionLink }} />
          <OrangeIcon color='#FF7A00' icon='arrowright' size='MD' />
        </a>
      </div>
    </div>
  )
}

export default SocialMediaCard
