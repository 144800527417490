// ################################################################################
// ### OS COMENTÁRIOS DESSA PÁGINA SÃO DOS ELEMENTOS QUE NÃO ESTÃO SENDO MAIS   ###
// ### UTILIZADOS POR CONTA DO ENDPOINT DA NEWSLLETTER QUE NÃO ESTÁ MAIS        ###
// ### FUNCIONANDO                                                              ###
// ################################################################################
// ### CARD REFERENTE À TAREFA: https://jira-inter.atlassian.net/browse/RCW-184 ###
// ################################################################################
import React from 'react'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { SectionEscolhaRecebaDicas } from './style'
import { orange } from 'src/styles/colors'

// import { Checkbox } from '@interco/inter-ui/components/Checkbox'
// import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
// import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type DicaType = {
  icon: string;
  titulo: string;
  subtitulo: string;
  name: string;
  selected?: boolean;
}

type EscolhaType = {
  lista: DicaType[];
  // setSelected: Function;
  // setOpenModal: Function;
  // setDataLayer: Function;
}

const EscolhaRecebaDicas = ({
  // setSelected,
  // setOpenModal,
  // setDataLayer,
  lista,
}: EscolhaType) => {
  // const [ sendDatalayerEvent ] = useDataLayer()
  // const dataLayer: IDataLayerParams = {
  //   section: 'dobra_02',
  //   element_action: 'click button',
  //   element_name: 'Quero receber',
  //   section_name: 'Escolha e receba grátis informações exclusivas em primeira mão',
  // }

  function isSelected (dica: DicaType) {
    return dica.selected ? 'selected' : ''
  }

  return (
    <SectionEscolhaRecebaDicas id='escolha-receba-dicas' className='position-relative py-5'>
      <div className='container '>
        <div className='d-flex my-2 justify-content-center align-items-center'>
          {/* <div className='col-12 escolha-receba__description '>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 fw-600 mb-3 text-grayscale--500 text-md-center '>
              Escolha e receba grátis informações exclusivas em primeira mão
            </h2>
            <div className='fs-18 lh-22 fs-lg-18 lh-lg-22 text-grayscale--400 text-md-center'>
              <p>
                Selecione a newsletter que você quer receber de acordo com o assunto de seu interesse e saia na frente com
                as informações que você precisa para investir.
              </p>
            </div>
          </div> */}
        </div>
        <div className='d-flex my-2 justify-content-center align-items-center'>
          <div className='row  escolha-receba__options '>
            {lista.map((dica: DicaType, key: number) => (
              <div key={key} className='col-12 col-md-6 col-xl-4 p-2'>
                <div className={`escolha-receba__card p-3 px-xl-4 h-100 ${isSelected(dica)} `}>
                  <div className='d-flex justify-content-between align-items-center mb-2 mb-xl-4'>
                    <OrangeIcon size='MD' icon={dica.icon} color={orange.extra} />
                    {/* <Checkbox
                      name={`formCheckbox${key}`}
                      id={`checkbox${key}`}
                      size={24}
                      className='escolha-receba__card__checkbox'
                      checked={!!isSelected(dica)}
                      onChange={() => setSelected(dica) }
                    /> */}
                  </div>
                  <div>
                    <h3 className='fs-16 lh-20 fs-md-20 lh-md-25 mb-2 mb-xl-4 '> {dica.titulo}</h3>
                  </div>
                  <div className='fs-14 lh-17 fs-md-16 lh-md-19'>
                    <p>
                      {dica.subtitulo}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div className='d-flex justify-content-center align-items-center'>
          <button
            type='button' title='Começar a Investir'
            className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mt-4 text-md-center'
            onClick={() => {
              setOpenModal(true)
              setDataLayer(dataLayer)
              sendDatalayerEvent(dataLayer)
            }}
          >
            Quero receber
          </button>
        </div> */}
      </div>
    </SectionEscolhaRecebaDicas>
  )
}

export default EscolhaRecebaDicas
