import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const SectionWrapper = styled.section`
  @media(min-width: ${breakpoints.md}) {
    min-height: 636px;
  }

  .font-inter {
    font-family: 'Inter' !important; 
  }

  p {
    font-family: 'Sora';
  }

  .social-media-cards {
    margin-top: 30px;
    
    .social-media-card {
      padding: 20px;
      min-height: 160px;

      p {
        span {
          margin-left: 15px;
        }
      }
    }
  }
  a {
    span {
      margin-right: 10px;
    }
  }

  .social-media-card {
    margin: 0 0 25px 0;
  }

  .description {
    @media(min-width: ${breakpoints.xxl}) {
      display: flex; 
      justify-content: space-between;
      align-items: center;
    }
  }
`
