import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale, orange } from 'src/styles/colors'

export const ContentHeader = styled.section`
  align-items: center;
  background: ${grayscale[100]};
  position: relative;
  min-height: calc(100vh - 64px);

  @media ${device.tablet} {
    min-height: 650px;
  }

  @media ${device.desktopLG} {
    min-height: 100vh
  }

  a {
    @media ${device.desktopLG} {
     max-width: 456px;
    }
  }

  .scroll-link {
    bottom: 20px;
    fill: ${orange.medium};
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  } 
`
export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    margin: 0 auto;

    @media ${device.desktopXXL} {
      width: 558px;
      float: right;
    }
  }
`
export const ScrollLink = styled.div`
  position: absolute;
  left: 50%;
  text-align: center;
  line-height: 1.67;
  transform: translateX(-50%);
  width: auto;
  bottom: 20px;
`

export const Form = styled.form`
  label.error {
    color: #F56A50;
  }
  
  button {
    width: 100%;
    max-width: 100%;
  }
`
