import styled from 'styled-components'

export const SectionWrapper = styled.section`
  min-height: 528px;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-multiple-carousel__arrow {
    svg {
      fill: #FF7A00 !important;
    }
  }

  .react-multi-carousel-dot {
    button {
      background-color: #FF7A00 !important;
    
    }
  }
`

export const BoxCarousel = styled.div`
  display: flex;
  align-items: center;

  img {
    max-width: 100%;
  }
`
