import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      heroImage: imageSharp(fluid: { originalName: { regex: "/invest-hero-image/" }}) {
        fluid(maxWidth: 558, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      rafaelAlves: imageSharp(fluid: { originalName: { regex: "/invest-rafael-alves/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      lucasRadd: imageSharp(fluid: { originalName: { regex: "/invest-lucas-radd/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      rafaelaVitoria: imageSharp(fluid: { originalName: { regex: "/invest-rafaela-vitoria/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
      gabrielaJoubert: imageSharp(fluid: { originalName: { regex: "/invest-gabriela-joubert/" }}) {
        fluid(maxWidth: 264, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
