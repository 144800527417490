import React, { useState } from 'react'

import useDomReady from 'src/hooks/window/useDomReady'

import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import OpportunitiesForm from 'src/components/UI/Forms/OpportunitiesForm'
import { hookLista, hookEmail } from 'src/hooks/contextHook'
import ContentData from './sections/escolha-receba-dicas/_contentData'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import * as URLs from 'src/config/api/Urls'

import Hero from './sections/hero/_hero'
import EscolhaRecebaDicas from './sections/escolha-receba-dicas/_EscolhaRecebaDicas'
import Carousel from './sections/Carousel/_Carousel'
import SigaOInter from './sections/Siga-o-inter/_SigaOInter'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const InvestInsights = () => {
  const domReady = useDomReady()
  const [ openModal, setOpenModal ] = useState(false)
  const [ lista, , setSelected, setSelectedAll ] = hookLista(ContentData)
  const [ email, setEmail ] = hookEmail()

  const [ dataLayer, setDataLayer ] = useState<IDataLayerParams>({
    section: 'dobra_01',
    element_action: 'click button',
    section_name: 'Invista melhor com nossas newsletters',
    element_name: 'Quero receber',
  })

  const newsletterFormModal = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <OpportunitiesForm
        url={`${URLs.CONTACT_FORM_POST_V5}/lp-inter-insights`}
        setEmail={setEmail}
        email={email}
        lista={lista}
        setSelected={setSelected}
        setSelectedAll={setSelectedAll}
        closeModal={() => setOpenModal(false)}
        dataLayer={dataLayer}
      />
    </Modal>
  )

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {newsletterFormModal}
        <Hero email={email} setEmail={setEmail} setOpenModal={setOpenModal} setDataLayer={setDataLayer} dataLayer={dataLayer} />
        <EscolhaRecebaDicas lista={lista} setSelected={setSelected} setOpenModal={setOpenModal} setDataLayer={setDataLayer} />
        <Carousel setOpenModal={setOpenModal} setDataLayer={setDataLayer} />
        <SigaOInter />
      </Layout>
    </Wrapper>
  )
}

export default InvestInsights
