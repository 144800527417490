import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'
import { rounded } from 'src/styles/rounded'
import { device } from 'src/styles/breakpoints'

export const SectionEscolhaRecebaDicas = styled.section`
@media ${device.desktopLG}{
  padding: 5rem 0 !important;
}
.escolha-receba{
  &__description{
    max-width: 780px;
  }
  &__card{
    border: 1px solid ${grayscale[200]};
    box-sizing: border-box;
    border-radius: ${rounded[5]};
    &.selected{
      border-color: ${orange.extra}
    }
    label{
      cursor: pointer;
    }
    &__checkbox{
      cursor: pointer;
      padding: 4px;
    }
  }
}

`
