import React, { ChangeEvent } from 'react'
import Img from 'gatsby-image'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import ScrollTo from 'src/components/ScrollTo'
import Icon from 'src/components/UI/MarkdownIcon'
import Input from 'src/components/Input'

import useWidth from 'src/hooks/window/useWidth'

import { ContentHeader, ImgSize, ScrollLink, Form } from './style'

import usePageQuery from '../../pageQuery'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

type HeroProps = {
  email: string;
  setEmail: Function;
  setOpenModal: Function;
  dataLayer: IDataLayerParams;
  setDataLayer: Function;
}

const Hero = ({ email, setEmail, setOpenModal, dataLayer, setDataLayer }: HeroProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ContentHeader className='d-flex py-5'>
      <div className='container'>
        <div className='row align-items-center justify-content-between'>
          <ImgSize className='col-12 col-md-6 order-md-last'>
            <Img fluid={data.heroImage.fluid} alt='Clientes acessando app e tomando café' />
          </ImgSize>
          <div className='col-12 col-md-6 mt-5 mt-md-0'>
            <p className='fs-20 lh-25 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500 fw-600'>
              Inter Invest Insights
            </p>
            <h2 className='fs-32 lh-40 fs-xl-48 lh-xl-60 text-grayscale--500 fw-600'>
              Invista melhor com nossas newsletters
            </h2>
            <p className='fs-18 lh-22 text-grayscale--400 fw-400 mt-2 mt-lg-4 mb-0'>
              Assine nossas newsletters gratuitas e receba no seu e-mail análises e insights dos
              nossos especialistas que vão ajudar você a fazer melhores escolhas de investimentos.
            </p>
            {/* <div className='d-none d-md-block mt-2 mt-lg-5'>
              <Form>
                <div className='row'>
                  <div className='col-12 col-lg-8'>
                    <Input
                      label='Email'
                      id='email'
                      name='email'
                      type='email'
                      placeHolder='Digite seu e-mail'
                      value={email}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setEmail(event.currentTarget.value) }
                    />
                  </div>
                  <div className='col-12 col-lg-8'>
                    <button
                      type='button'
                      title='Começar a Investir'
                      onClick={() => {
                        setOpenModal(true)
                        setDataLayer(dataLayer)
                        sendDatalayerEvent({
                          section: 'dobra_01',
                          element_action: 'click button',
                          element_name: 'Quero receber',
                          section_name: 'Invista melhor com nossas newsletters',
                        })
                      }}
                      className='btn btn-orange--extra btn--lg rounded-2 fs-14 fw-600 text-none mt-4 text-md-center'
                    >
                      Quero receber
                    </button>
                  </div>
                </div>
              </Form>
            </div> */}
          </div>
        </div>
      </div>
      {useWidth() > 576 &&
        (
          <ScrollTo
            to='#escolha-receba-dicas'
            styles='text-center d-none d-md-block'
            section='dobra_01'
            sectionName='Invista melhor com nossas newsletters'
            elementName='Conheça mais'
          >
            <ScrollLink>
              <p className='text-orange--base mb-0 fs-14 fs-lg-16 fw-600'>Conheça mais</p>
              <Icon color='orange--base' width='24' height='24' icon='navigation/chevron-down' directory='v2' />
            </ScrollLink>
          </ScrollTo>
        )
      }
    </ContentHeader>
  )
}

export default Hero
