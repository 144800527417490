export default [
  {
    icon: 'investments',
    titulo: 'E o mercado?',
    subtitulo: 'As principais informações sobre investimentos da semana.',
    name: 'mercado',
  },
  {
    icon: 'book-open',
    titulo: 'Inter Portfólios',
    subtitulo: 'Receba indicações antes restritas a investidores com mais de R$ 1 milhão investidos.',
    name: 'portfolio',
  },
  {
    icon: 'inter-research',
    titulo: 'Equity Research',
    subtitulo: 'Análises sobre as principais empresas listadas na bolsa de valores.',
    name: 'equity_research',
  },
  {
    icon: 'mei',
    titulo: 'Research Macro',
    subtitulo: 'Tenha em mãos a visão de mercado da nossa economista-chefe, Rafaela Vitória.',
    name: 'macro_research',
  },
  {
    icon: 'wallet',
    titulo: 'Inter Strategy',
    subtitulo: 'Recomendações para fazer sua carteira decolar de acordo com seu perfil de investidor.',
    name: 'inter_strategy',
  },
  {
    icon: 'credit',
    titulo: 'Fundos Imobiliários',
    subtitulo: 'Descubra mensalmente em quais ativos FIIs ficar de olho.',
    name: 'fundos_imobiliarios',
  },
]
